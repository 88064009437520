import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ExpenseRelationships = {
    contact: ToOneRelationship<ModelType.CONTACTS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    pdf: ToOneRelationship<ModelType.PDFS>;
};

type ExpenseAttributes = {
    title: string;
    description: string;
    amount: number;
    date: string;
    expenseType: string;
    expenseCategory: string;
    paymentMethod: string;
    dueAt: string;
    isPaid: boolean;
    paidBy: string;
    paidByDetails: string;
    vatRate: number;
};

type ExpenseMeta = {};

export default class ExpenseSchema extends BaseSchema<ExpenseAttributes, ExpenseMeta, ExpenseRelationships> {
    protected type: ModelType = ModelType.EXPENSES;
}
