import { ModelType } from '~/app/base/schemas/BaseSchema';
import EndpointFactory from '~/app/factories/EndpointFactory';
import PdfEndpoint from '~/app/pdf/endpoints/PdfEndpoint';
import Pdf from '~/app/pdf/models/Pdf';

export default function useUploadPdf() {
    const uploading = ref<boolean>(false);
    const pdf = ref<Pdf | null>(null);

    const upload = async (file: File): Promise<void> => {
        if (uploading.value) return;

        uploading.value = true;
        try {
            const endpoint = EndpointFactory.make(ModelType.PDFS) as PdfEndpoint;
            const response = await endpoint.upload(file);

            if (response.error) {
                useToasteoError();
                return;
            }

            useToasteoSuccess('invoicing.expenses.pdf_uploaded_successfully');
            pdf.value = response.data;
        } catch (error) {
            console.error('PDF upload failed:', error);
            useToasteoError();
        } finally {
            uploading.value = false;
        }
    };

    return {
        uploading,
        pdf,
        upload,
    };
}
